<template>
    <SfBasicTemplate
        v-if="entry"
        class="template--spacing-top"
    >
        <SfSection layout="home-intro">
            <template #aside>
                <SfMainMenuList
                    :index-tabs="false"
                    aria-hidden="true"
                />
            </template>

            <template #default>
                <SfHighlights
                    v-if="entry.featuredEntries && entry.featuredEntries.length > 0"
                    :entries="entry.featuredEntries"
                />
            </template>
        </SfSection>

        <SfSection>
            <QueryDiscoverEntries
                #default="{ entries }"
                :variables="{
                    limit: 10,
                    uri: null,
                    showOnHome: true
                }"
            >
                <SfDiscoverStream
                    :entries="entries"
                    :global-entries="entry.streamGlobalEntries"
                    :order="entry.streamBehaviour"
                    class="discover-stream--mirrored"
                />
            </QueryDiscoverEntries>
        </SfSection>
    </SfBasicTemplate>
</template>

<script setup>
import SfBasicTemplate from '~/patterns/templates/basic.vue';
import SfSection from '~/patterns/organisms/section/section';
import SfDiscoverStream from '~/patterns/organisms/discover-stream/discover-stream';
import SfMainMenuList from '~/patterns/molecules/menu-list/presets/main-menu.vue';
import SfHighlights from '~/patterns/organisms/highlights/highlights.vue';

import QueryDiscoverEntries from '~/components/Query/QueryDiscoverEntries.vue';

import HomeQuery from '~/graphql/queries/pages/home.graphql';

const { variables } = useDefaultVariables();

const response = await useAsyncQuery({
    query: HomeQuery,
    variables
});

const { craftEntry: entry } = useEntryPage(response);

useCollapse();
useNavigationVisibleOnScroll();
</script>
