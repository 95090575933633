<template>
    <div class="sf-highlights">
        <div class="sf-highlights__wrapper">
            <div class="sf-highlights__title">
                <h2>{{ t('latest') }}</h2>
            </div>

            <div class="sf-highlights__entries">
                <NuxtLink :to="urlToPath(selectedEntry.url)">
                    <SfImageStack
                        :images="imageStack"
                        :selected-image-index="selectedEntryIndex"
                        @image-hover="onImageHover"
                    >
                        <template #badge="{ imageIndex }">
                            <SfDateBadge
                                v-show="filteredEntries[imageIndex].entryType === 'event'"
                                :date="filteredEntries[imageIndex].fromDate"
                            />
                        </template>

                        <template #caption>
                            <h3>{{ selectedEntry.title }}</h3>
                        </template>
                    </SfImageStack>
                </NuxtLink>
            </div>

            <ul>
                <li
                    v-for="(entry, index) in filteredEntries"
                    :key="index"
                    class="sf-highlights-list__title"
                >
                    <!--eslint-disable-->
                    <NuxtLink
                        :data-active="index === selectedEntryIndex"
                        :to="urlToPath(entry.url)"
                        @mouseover.native="updateSelected(index)"
                    >
                        {{ truncate(entry.title, 100) }}
                    </NuxtLink>
                    <!--eslint-enable-->
                </li>
            </ul>
            <div class="sf-highlights__more">
                <p>
                    {{ t('more') }}

                    <NuxtLink :to="localePath('news')">
                        {{ t('news') }}
                    </NuxtLink>

                    {{ t('or') }}

                    <NuxtLink :to="localePath('events')">
                        {{ t('agenda') }}
                    </NuxtLink>
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import SfImageStack from '~/patterns/molecules/image-stack/image-stack.vue';
import SfDateBadge from '~/patterns/atoms/date-badge/date-badge.vue';

const localePath = useLocalePath();

const { t } = useI18n({
    useScope: 'local'
});

const props = defineProps({
    entries: {
        type: Array,
        default: () => {
            return [];
        }
    }
});

const filteredEntries = ref(props.entries.slice(0, 3));
const selectedEntryIndex = ref(0);

const updateSelected = (index) => {
    if (import.meta.server) {
        return;
    }

    selectedEntryIndex.value = index;
};

const onImageHover = (imageIndex) => {
    updateSelected(imageIndex);
};

const imageStack =  filteredEntries.value.map((entry) => {
    return entry.featuredImage[0];
});

const selectedEntry = () => {
    return props.entries[selectedEntryIndex.value];
};

// const shuffle = (array) => {
//     let currentIndex = array.length, randomIndex;

//     while (currentIndex !== 0) {
//         randomIndex = Math.floor(Math.random() * currentIndex);
//         currentIndex--;
//         [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
//     }

//     return array;
// };
</script>

<style lang="less" src="./highlights.less"></style>

<i18n lang="json">
{
    "nl": {
        "latest": "actueel",
        "more": "meer",
        "news": "nieuws",
        "or": "of",
        "agenda": "agenda"
    },
    "en": {
        "latest": "latest news",
        "more": "more",
        "news": "news",
        "or": "or",
        "agenda": "agenda"
    }
}
</i18n>
