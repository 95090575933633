export const useNavigationVisibleOnScroll = () => {
    const uiStore = useUiStore();
    const { scrollPosition } = storeToRefs(uiStore);

    uiStore.toggleNavigationVisibility(false);

    onActivated(() => {
        uiStore.toggleNavigationVisibility(false);
    });

    onDeactivated(() => {
        uiStore.toggleNavigationVisibility(true);
    });

    watch(scrollPosition, () => {
        if (scrollPosition.value > 300) {
            uiStore.toggleNavigationVisibility(true);
        } else {
            uiStore.toggleNavigationVisibility(false);
        }
    });

    return {
        scrollPosition,
        uiStore
    };
};
